import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import star from "../placeholders/icons/star.svg"
import message from "../placeholders/icons/message.svg";
import share from "../placeholders/icons/share.svg";
import femaleAvatar from "../placeholders/pictures/female_avatar.svg";
import maleAvatar from "../placeholders/pictures/male_avatar.svg";
function Team() {
    return (
        <Layout>
            <SEO
                keywords={[`team`, `service`, `website`]}
                title="Home"
            />
            <section className="pt-8 px-4 pb-4">
                <h2 className="text-4xl mb-2 leading-tight font-heading">Das Team</h2>
                <p className="max-w-xl mb-8 text-gray-500">Software-Entwicklung liegt uns im Blut.</p>
                <div className="flex flex-wrap -mx-4 text-center">
                    <div className="w-full lg:w-1/3 px-4 mb-8">
                        <div className="h-full pb-6 rounded shadow-md">
                            <img className="mx-auto mb-4" src={maleAvatar} alt="" />
                            <div className="px-8">
                                <h3 className="text-xl font-heading font-semibold">Christian Hohlfeld</h3>
                                <span>Geschäftsführer</span>
                                <div className="flex justify-center py-3 my-4 mx-12 border-t border-b"><img className="w-8 h-8 mx-3" src={message} alt="" /><img className="w-8 h-8 mx-3" src={share} alt="" /><img className="w-8 h-8 mx-3" src={star} alt="" /></div>
                                <p>Solution Architect | Software-Engineer</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 px-4 mb-8">
                        <div className="h-full pb-6 rounded shadow-md">
                            <img className="mx-auto mb-4" src={femaleAvatar} alt="" />
                            <div className="px-8">
                                <h3 className="text-xl font-heading font-semibold">Leandra Graf</h3>
                                <span>Support</span>
                                <div className="flex justify-center py-3 my-4 mx-12 border-t border-b"><img className="w-8 h-8 mx-3" src={message} alt="" /><img className="w-8 h-8 mx-3" src={share} alt="" /><img className="w-8 h-8 mx-3" src={star} alt="" /></div>
                                <p>Service-Specialist</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 px-4 mb-8">
                        <div className="h-full pb-6 rounded shadow-md">
                            <img className="mx-auto mb-4" src={maleAvatar} alt="" />
                            <div className="px-8">
                                <h3 className="text-xl font-heading font-semibold">Robin Windey</h3>
                                <span>CTO</span>
                                <div className="flex justify-center py-3 my-4 mx-12 border-t border-b"><img className="w-8 h-8 mx-3" src={message} alt="" /><img className="w-8 h-8 mx-3" src={share} alt="" /><img className="w-8 h-8 mx-3" src={star} alt="" /></div>
                                <p>Software-Engineer | Security Expert</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default Team;
